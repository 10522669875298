<template>
  <div class="login">
    <v-row no-gutters>
      <v-col
        cols="12"
        md="7"
        class="login-bg mobile-hidden"
      ></v-col>
      <v-col
        cols="12"
        md="5"
      >
        <div class="text-center">
          <a
            href="https://www.soocel.com"
            class="d-flex justify-center align-center"
          >
            <img
              src="@/assets/images/soocel-logo.png"
              height="45"
              class="logo"
            />
          </a>
        </div>

        <h2 class="text-center">Reset Password</h2>

        <v-form
          class="login-form"
          v-model="valid"
          lazy-validation
          v-on:submit.prevent="onSubmit"
          ref="setPass"
        >
          <v-alert
            outlined
            type="error"
            v-if="alert"
          >{{ alertMsg }}</v-alert>

          <label class="label">Password</label>
          <v-text-field
            v-model="user.password"
            :rules="setPassRules.password"
            type="password"
            placeholder="Set a password"
            solo
          ></v-text-field>

          <label class="label">Confirm Password</label>
          <v-text-field
            v-model="user.confirmPassword"
            :rules="setPassRules.confirmPassword"
            type="password"
            placeholder="Confirm your password"
            solo
          ></v-text-field>

          <v-btn
            depressed
            dark
            large
            block
            color="primary"
            style="width: 252px;"
            :disabled="loading"
            :loading="loading"
            type="submit"
            @click="updateInfo()"
          >Update</v-btn>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { resetPassword } from '@/api/user'

export default {
  data () {
    return {
      valid: true,
      loading: false,
      user: {
        token: this.$route.query.token,
        password: null,
        confirmPassword: null
      },
      setPassRules: {
        password: [
          v => !!v || 'Password is required'
        ],
        confirmPassword: [
          v => !!v || 'Password is required',
          v => v === this.user.password || 'Password not match'
        ]
      },
      alertMsg: '',
      alert: false
    }
  },
  methods: {
    async updateInfo () {
      if (this.$refs.setPass.validate()) {
        try {
          this.loading = true
          const data = await resetPassword(this.user)
          if (data.success) {
            this.$snackbar({
              message: 'Password reset successfully',
              timeout: 2000
            })
            this.$router.push('/user/login')
          } else {
            this.alertMsg = data.message
            this.alert = true
            this.loading = false
          }
        } catch (error) {
          this.loading = false
          this.alertMsg = 'Something went wrong'
          this.alert = true
          console.log(error)
        }
      } else {
        console.log('error submit!!')
        return false
      }
    }
  }
}
</script>

<style scoped>
</style>
